<template>
    <div>
        <!-- 绑定修改微信账号页面 -->
        <div class="loginBox">
            <el-form :model="bindeChangeForm" :rules="rules" ref="bindeChangeForm" class="demo-ruleForm" :inline="true" :hide-required-asterisk='true'>
                <div class="titleBox">
                    <div class="title">{{type == 1 ? '绑定微信号': '修改绑定微信号'}}</div>
                    <div class="subTitle">请登录您的账号</div>
                </div>
                <template v-if="!isNext">
                    <el-form-item label="" prop="mobile_no">
                        <el-input style="width: 370px;" type="text"  v-model="bindeChangeForm.mobile_no" placeholder="手机号码" :clearable="true"></el-input>
                    </el-form-item> 
                    <el-form-item label="" prop="password">
                        <el-input style="width: 370px" type="text"  show-password v-model="bindeChangeForm.password" placeholder="登录密码" :clearable="true"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="sms_code">
                        <el-input style="width: 270px; marginRight: 30px" type="text"  v-model="bindeChangeForm.sms_code" placeholder="验证码" :clearable="true"></el-input>
                        <el-button type="primary"  @click="getCode(bindeChangeForm.mobile_no)">获取</el-button>
                    </el-form-item>              
                    <el-form-item>
                        <el-button style="width: 290px; borderRadius: none" type="primary" @click="submiteLogin('bindeChangeForm')">下一步</el-button>
                        <el-button  @click="cancel">取消</el-button>
                    </el-form-item>
                </template>
                <template v-else>
                    <div class="mobile_no">绑定账号: {{bindeChangeForm.mobile_no}}</div>
                    <Qrcode/>
                </template>
            </el-form>
        </div>
    </div>
</template>

<script>
    import * as Login from '../../../api/login'
    import { encryPwd } from '../../../utils/encryPwd'
    import Qrcode from './Qrcode.vue'
    import { mapState, mapActions } from 'vuex'
    import * as Exp from '../../../utils/RegExp'
    export default {
        props: ['type'],
        data() {
            return {
                bindeChangeForm: {
                    mobile_no: '',
                    password: '',
                    sms_code: ''
                },
                rules: {
                    mobile_no: [{required: true, message: '请输入手机号', trigger: 'blur' }],
                    password: [{required: true, message: '请输入密码', trigger: 'blur' }],
                },
                isNext: false,
            }
        },
        components: {
            Qrcode
        },
        methods: {
            ...mapActions('Login',[
                'login'
            ]),
            submiteLogin(formName) {
                this.$refs[formName].validate((valid) => {
                    if ( valid ) {
                        let params = {
                            login_type: 2,
                            password: encryPwd(this.bindeChangeForm.password),
                            mobile_no: this.bindeChangeForm.mobile_no,
                            sms_code: this.bindeChangeForm.sms_code
                        }
                        Login.Login(params).then(res => { //登录
                            if(res.access_token) { //成功
                                this.login(res) //存储数据
                                localStorage.setItem('initUserInfo', JSON.stringify(params))
                                this.isNext = true
                            }
                        })
                    }
                })
            },
            //获取手机验证码
            getCode(mobile) {
                if(!mobile) {
                    this.$message.error('请输入手机号！')
                    return false
                }
                if(!Exp.mobile.test(mobile)) {
                    this.$message.error('手机号格式有误！')
                } else {
                    //获取验证码
                    Login.sendCode({mobile_no: mobile}).then(res => {
                        this.$message({
                            message: '已发送！',
                            type: 'success'
                        })
                    })
                }
            },
            cancel() {
                this.$emit('cancelChange')
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/styles/vars.scss';
    .loginBox{
        margin-top: 50px;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 400px;
        height: 400px;
        border-radius: 5px;
        // box-shadow: 0px 2px 2px 2px rgb(201, 201, 201);
        .demo-ruleForm{
            display: flex;
            flex-direction: column;
            align-items: center;
            .titleBox{
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                margin: 20px 0 40px 0 ;
                .title{
                    font-size: 30px;
                    font-weight: 400;
                }
                .subTitle{
                    margin-top: 10px;
                    font-size: 15px;
                }
            }
        }
    }
</style>