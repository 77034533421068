<template>
    <div class="qrbox">
        <!-- <img :src="img" alt=""> -->
        <wxlogin
            id="login_container"
            self_redirect="false"
            :appid="appid"
            :scope="'snsapi_login'"
            :theme="'black'"
            :redirect_uri="encodeURIComponent(url)"
            :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsNCiAgZGlzcGxheTogbm9uZTsNCn0NCi5pbXBvd2VyQm94IC5zdGF0dXMuc3RhdHVzX2Jyb3dzZXIgew0KICBkaXNwbGF5OiBub25lOw0KfQ0KLmltcG93ZXJCb3ggLnFyY29kZSB7DQogIGJvcmRlcjogbm9uZTsNCiAgd2lkdGg6IDIwMHB4Ow0KICBoZWlnaHQ6IDIwMHB4Ow0KfQ0KLmltcG93ZXJCb3ggLnN0YXR1c3sNCiAgZGlzcGxheTogbm9uZQ0KfQ=='" rel="external nofollow" 
            >
        </wxlogin>
    </div>
</template>

<script>
    import wxlogin from 'vue-wxlogin';
    import config from '../../../config/apiConfig'
    export default {
        data() {
            return {
                // url: 'http://ops.wecloudx.com',
                // appid: 'wx4641d61b5984afdf',
                url: config.url,
                appid: config.appid,
                img:require('../../../assets/images/u1412_11.png')
            }
        },
        components: {
            wxlogin
        },
        mounted() {
            let url = document.getElementById("login_container").contentWindow.location.href
            // document.getElementsByTagNameNS()
            // console.log('url',url)
        },
        beforeUpdate() {
             let url = document.getElementById("login_container").contentWindow.location.href
            // document.getElementsByTagNameNS()
            // console.log('url',url)
        },
    }
</script>

<style lang="scss" scoped>
    .qrbox{
        width: 250px;
        height: 250px;
        display: flex;
        justify-content: center;
        // background: rgb(145, 144, 144);
        img{
            width: 250px;
            height: 250px;
        }
    }
</style>