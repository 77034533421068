<template>
    <div class="footerBox">
        <div class="conpany">深圳市微云信众技术有限公司 © 2020-2021</div>
        <div class="call">客服电话：4007766000</div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    .footerBox{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        bottom: 10px;
        font-size: 10px;
        color: #929292;
        .call{
            margin-top: 5px;
        }
    }
</style>