import request from '../utils/request'


//登录
export function Login (params) {
    return request({
        url: '/v1/bq/users/login',
        method: 'post',
        data: params
    })
}


//绑定
export function LoginBind (params) {
    return request({
        url: '/v1/bq/users/bind',
        method: 'post',
        data: params
    })
}

//发送短息验证码
export function sendCode (params) {
    return request({
        url: '/v1/bq/sms',
        method: 'post',
        data: params
    })
}