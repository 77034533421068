<template>
    <div class="container">
        <div class="top">
            <div>
                <el-popover
                    placement="top-start"
                    title=""
                    width="160"
                    trigger="hover"
                >   
                    <div class="hoveBox">
                        <img class='hoveImg' src="http://cdn.wecloudx.com/bankCodeStatic/wxQrcode.jpg" alt="">
                        <div>微信扫一扫</div>
                    </div>
                    <el-button type='text' slot="reference" style="color:#fff">小程序绑卡报表</el-button>
                </el-popover>
            </div>
        </div>
        <div class="header">
            <img class="logo" :src="imgs.logo" alt="">
            <span class="title">银行码管理系统</span>
        </div>
        <div class="body" v-show="!isBindChangeWX">
            <el-col :span="12">
                <div class="productTitle">微信银行码</div>
                <div class="navList">
                    <el-col :span='16'>
                        <p class="info">银行码可帮助银行推广自己绑卡业务，具有灵活免开发的特点，可有效降低银行线下运营门槛，促进银行线下网点的业务转化。</p>
                        <div class="infoBox">
                            <el-col :span="5" v-for="(item, index) in navArr" :key="index">
                                <div class="navItem">
                                    <el-image
                                    style="width: 30px; height: 30px"
                                    :src="item.icon"
                                    fit="fit"></el-image>
                                    <p class="name" style="fontSize: 13px;marginTop: 10px">{{item.name}}</p>
                                </div>
                            </el-col>
                        </div>
                    </el-col>
                </div>
            </el-col>
            <el-col :span='12'>
                <div class="right">
                    <div class="loginBox">
                        <el-form :model="logoPhoneForm" :rules="rules" ref="logoPhoneForm" class="demo-ruleForm" :inline="true" :hide-required-asterisk='true'>
                            <el-radio-group size="small" v-model="labelPosition" class="mothods">
                                <el-radio-button label="left">扫码登陆</el-radio-button>
                                <el-radio-button label="right">账号登录</el-radio-button>
                            </el-radio-group>
                            <template v-if="labelPosition == 'right'">
                                <el-form-item label="" prop="mobile_no">
                                    <el-input style="width: 350px" type="text"  v-model="logoPhoneForm.mobile_no" placeholder="手机号码" :clearable="true"></el-input>
                                </el-form-item> 
                                <el-form-item label="" prop="password">
                                    <el-input style="width: 350px" type="text"  show-password v-model="logoPhoneForm.password" placeholder="登录密码" :clearable="true"></el-input>
                                </el-form-item>
                                <el-form-item label="" prop="sms_code">
                                    <el-input style="width: 250px; marginRight: 30px" type="text" v-model="logoPhoneForm.sms_code" placeholder="验证码" :clearable="true"></el-input>
                                    <el-button type="primary"  @click="getCode(logoPhoneForm.mobile_no)">获取</el-button>
                                </el-form-item>              
                                <el-form-item>
                                    <el-button style="width: 350px; borderRadius: none" type="primary"  @click="submiteLogin('logoPhoneForm')">登录</el-button>
                                </el-form-item>
                            </template>
                            <template v-else> 
                                <Qrcode/>
                            </template>
                            <div class="loginBottomBtn" v-if="labelPosition == 'right'">
                                <div class="btn" @click="bindChange(1)">绑定微信号</div>
                                <div class="btn changeWx"  @click="bindChange(2)">修改微信</div>
                            </div>
                        </el-form>
                    </div>
                </div>
            </el-col>
        </div>
        <div class="body bindchange" v-show="isBindChangeWX">
            <BindChange :type="changeType" @cancelChange="cancle"/>
        </div>
        <PageFooter/>
        <!-- 绑定微信弹窗 -->
        <el-dialog
            custom-class="dialog"
            title=""
            :visible.sync="centerDialogVisible"
            width="400px"
            center>
            <div class="qrcodeBox">
                <div class="title">绑定微信</div>
                <div class="subTitle">绑定账号 {{hidPhone(logoPhoneForm.mobile_no)}}</div>
                <Qrcode/>
                <div class="footTitle">绑定微信后，即可使用扫码登录</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import PageFooter from './component/pageFooter.vue'
    import BindChange from './component/bindChange.vue'
    import Qrcode from './component/Qrcode.vue'

    import * as Login from '../../api/login'
    import * as Permission from '../../api/authority'
    import * as Exp from '../../utils/RegExp'
    import { encryPwd, getUrlKey, geTel} from '../../utils/encryPwd'
    import wxlogin from 'vue-wxlogin'
    import { mapState, mapActions } from 'vuex'
    export default {
        data() {
            return {
                imgs: {
                    logo: require('../../assets/images/logo1.png')
                },
                navArr: [
                    {
                        id: 1, icon: require('@/assets/images/u20.png'), name: '免开发'
                    },{
                        id: 2, icon: require('@/assets/images/u24.png'), name: '便捷配置'
                    },{
                        id: 3, icon: require('@/assets/images/u28.png'), name: '多样渠道'
                    },{
                        id: 4, icon: require('@/assets/images/u32.png'), name: '数据清晰'
                }],
                labelPosition: 'right', // right=>2  手机号    left=>3  微信      7微信小程序登录
                logoPhoneForm: {
                    // mobile_no: '18202744309', //测试
                    // mobile_no: '13800000001',  //本地
                    // password: 'test@123',
                    mobile_no: '',  //本地
                    password: '',
                    sms_code: ''
                },
                rules: {
                    mobile_no: [{required: true, message: '请输入手机号', trigger: 'blur' }],
                    password: [{required: true, message: '请输入密码', trigger: 'blur' }],
                },
                url: '',
                isBindChangeWX: false, //是否为绑定或修改微信账号
                changeType: 1,  //1 => 绑定，2 =>修改
                centerDialogVisible: false,
            }
        },
        mounted() {
            let initUserInfo = JSON.parse(localStorage.getItem('initUserInfo'))  //扫码回调后存储的数据
            localStorage.removeItem('initUserInfo')
            
            let code = getUrlKey('code') //扫码回调路径带的code参数
            if(typeof(code) == 'string') { //扫码方式的处理
                let obj = {
                    code,
                    login_type: 3  // 枚举: 2-手机号,3-微信
                }
                if(initUserInfo && initUserInfo.mobile_no){  //来自修改绑定或者添加绑定的回调
                    // 直接调用绑定
                    this.bindWx(obj)
                } else {
                    localStorage.removeItem('token')
                    Login.Login(obj).then(res => { //登录
                        this.login(res)
                        if(!res.code && res.access_token) {  //已绑定
                            this.getPermiList()
                        } else if (res.code && res.code === 10007) { //账号未绑定
                            this.labelPosition = 'right'
                            this.$message({
                                message: '账号未绑定，请使用账号密码登录绑定！',
                                type: 'warning'
                            });
                        }
                    })
                }
            }
        },
        components: {
            wxlogin,
            PageFooter,
            BindChange,
            Qrcode
        },
        computed: {
            // ...mapState('Login',{
            //     loginInfos: state => state.loginInfos
            // })
        },
        methods: {
            ...mapActions('Login',[
                'login'
            ]),
            submiteLogin(formName) {
                let login_type = ''
                switch (this.labelPosition) {
                    case 'right':  login_type = 2
                        break;
                    case 'left':  login_type = 3
                        break;
                }

                let params = {
                    login_type,
                    password: encryPwd(this.logoPhoneForm.password),
                    mobile_no: this.logoPhoneForm.mobile_no,
                    sms_code: this.logoPhoneForm.sms_code
                }
                this.$refs[formName].validate((valid) => {
                    if ( valid ) {
                        localStorage.removeItem('token')
                        Login.Login(params).then(res => { //登录
                            this.login(res)
                            if(res.access_token && res.is_bind_wechat) {  //已绑定
                                this.getPermiList()
                            } else if (res.access_token && !res.is_bind_wechat) { //账号未绑定
                                // this.bindWx(params) //绑定
                                localStorage.setItem('initUserInfo', JSON.stringify(params))
                                this.centerDialogVisible = true
                            }
                        })
                    }
                })
            },
            bindChange(type) {
                this.isBindChangeWX = true
                this.changeType = type
            },
            //绑定
            bindWx(params) {
                Login.LoginBind(params).then(res => {
                    if(res.id>0) {
                        this.getPermiList()
                    } else {
                        
                    }
                })
            },
            //获取用户所属权限列表
            getPermiList() {
                Permission.permissionList({current_page:1,page_size: 999}).then( res => {
                    let permissionList = []
                    if(res.list) {
                        res.list.forEach(item => {
                            permissionList.push(item.key)
                        });  
                    }
                    localStorage.setItem('permissions', permissionList)
                    const ee = localStorage.getItem('permissions')
                    this.$router.push('/bankCodeSet')
                })
                    // this.$router.push('/bankCodeSet')

            },
            hidPhone(value) {
                return geTel(value)
            },
            //获取手机验证码
            getCode(mobile) {
                if(!mobile) {
                    this.$message.error('请输入手机号！')
                    return false
                }
                if(!Exp.mobile.test(mobile)) {
                    this.$message.error('手机号格式有误！')
                } else {
                    //获取验证码
                    Login.sendCode({mobile_no: mobile}).then(res => {
                        this.$message({
                            message: '已发送！',
                            type: 'success'
                        })
                    })
                }
            },
            cancle() {
                this.isBindChangeWX = false
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/vars.scss';
    .container{
        width: 100vh;
        margin: 0;
        padding: 0;
        .top{
            width: 100vw;
            height: 80px;
            background: $color-header;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: flex-end ;
            font-size: 13px;
            div{
                margin-right: 20px;
            }

        }
        .header{
            width: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 20px;
            .logo{
                width: 200px;
                height: 50px;
            }
            .title{
                margin-left: 10px;
                line-height: 25px;
                padding-left: 20px;
                border-left: 1px solid;
                font-size: 25px;
                // font-weight: bold;
            }
        }
        .body{
            box-sizing: border-box;
            width: 100vw;
            padding: 80px;
            .productTitle{
                font-weight: bold;
                font-size: 50px;
                color: #000 ;
            }
            .navList{
                .info{
                    font-size: 20px;
                    margin: 40px 0;
                }
                .infoBox{
                    margin-top: 10px;
                    display: inline-block;
                    // height: 300px;
                    width: 100%;
                    // background: rgb(209, 178, 178);
                    .navItem{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    }
                }
            }
            .right{
                width: 100%;
                height: 300px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                .loginBox{
                    // margin-top: 50px;
                    margin-right: 30px;
                    // display: inline-block;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    width: 400px;
                    height: 400px;
                    // background: rgb(187, 55, 55);
                    border-radius: 5px;
                    box-shadow: 0px 2px 2px 2px rgb(201, 201, 201);
                    .demo-ruleForm{
                        // margin-left: 50%;
                        // transform: translate(-50%);
                        display: flex;
                        flex-direction: column;
                        // justify-content: center;
                        align-items: center;
                        .mothods{
                            display: flex;
                            justify-content: center;
                            margin-top: 20px;
                            padding-bottom: 30px;
                        }
                        .loginBottomBtn{
                            margin-top: 25px;
                            font-size: 13px;
                            color: $color-default;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .changeWx{
                                line-height: 12px;
                                padding-left: 15px;
                                border-left: 1px solid #d2d2d2;
                                margin-left: 15px;
                            }
                            .btn:hover{
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        .bindchange{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
    }
</style>
<style lang="scss">
    @import '../../styles/vars.scss';
    .el-popover{
        background: #000;
    }
    .hoveBox{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #fff;
        .hoveImg{
            display: inline-block;
            width: 150px;
            height: 150px;
            margin-bottom: 5px;
        }
    }
    .dialog{
        border-radius: 15px;
        .qrcodeBox{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .title{
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 20px;
                color: #000;
            }
            .subTitle{
                font-size: 14px;
            }
            .footTitle{
                margin-top: 20px;
                color: $color-default;
            }
        }
    }
</style>